import {computed, onBeforeUnmount, nextTick} from "vue"
import {useStore} from "vuex"
import {useFetchState} from "@/composables/useFetchState"
import ApiService from "@/services/api_service"
import {ITableField} from "@/types"
import GlobalService from "@/services/global_service"
import {useAppUtils} from "@/composables/useAppUtils"

export function useDataTable (options) {

  const store = useStore()

  const {
    storeNamespace
  } = options

  const {
    changeStateParams,
    fetchState,
  } = useFetchState({
    storeNamespace,
  })

  const selectedItemId = computed(() => store.getters[storeNamespace + '/selectedItemId'])
  const pageCurrent = computed(() => store.getters[storeNamespace + '/pageCurrent'])

  const {
    isMayContinue,
    showLeftPanelLoader,
    hideLeftPanelLoader,
    showPanelsLoaders,
    hidePanelsLoaders,
  } = useAppUtils()

  const scrollToSelected = () => {
    document.querySelector('.screen-panel-left-datatable .selected')?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    })
  }

  const changeSortByHandler = async (fieldName, panelLoader: 'left' | 'right' | 'both' = 'left') => {
    console.log('changeSortByHandler', fieldName, panelLoader)
    if (!await isMayContinue()) { return false }
    //showLeftPanelLoader()
    showPanelsLoaders(panelLoader)
    await changeStateParams({order: fieldName, reverse: false})
    await hidePanelsLoaders()
  }

  const changeSortDescHandler = async (isDesc, panelLoader: 'left' | 'right' | 'both' = 'left') => {
    console.log('changeSortDescHandler', isDesc, panelLoader)
    if (!await isMayContinue()) { return false }
    //showLeftPanelLoader()
    showPanelsLoaders(panelLoader)
    await changeStateParams({order: store.getters[storeNamespace + '/order'], reverse: isDesc})
    await hidePanelsLoaders()
  }

  const clickDatatableRowHandler = async (id: number) => {
    if (selectedItemId.value !== id) {
      await changeStateParams({selectedItemId: id})
    }
  }

  // const changePageHandler = async (pageNumber: number, event) => {
  //   if (pageCurrent.value !== pageNumber && !(event.metaKey || event.ctrlKey)) {
  //     await changeStateParams({pageCurrent: pageNumber, selectedItemId: null})
  //   }
  // }

  const setFieldsCancelTokenId = 'setFields_' + storeNamespace

  const cancelSetFieldsRequest = () => {
    console.log('cancelSetFieldsRequest', setFieldsCancelTokenId)
    ApiService.cancelRequest(setFieldsCancelTokenId)
  }

  onBeforeUnmount(() => {
    cancelSetFieldsRequest()
  })

  const columnsChangeHandler = async (fields: ITableField[]) => {
    console.log('columnsChangeHandler', fields)
    if (!await isMayContinue()) { return false }
    //showLeftPanelLoader()
    store.commit(storeNamespace + '/set_fields', fields)
    cancelSetFieldsRequest()
    await GlobalService.setFields(storeNamespace, fields, {
      //obj_type: 'dash_list_view',
      cancelTokenId: setFieldsCancelTokenId,
    })

    await fetchState()
  }

  const columnResizeHandler = async (field: ITableField) => {
    await GlobalService.setField(storeNamespace, field, {
      //obj_type: 'dash_list_view',
      //cancelTokenId: setFieldsCancelTokenId,
    })
      // .finally(async () => {
      //   await fetchState()
      // })
  }

  const resetView = async () => {
    if (!await isMayContinue()) { return false }
    showLeftPanelLoader()
    await nextTick()
    cancelSetFieldsRequest()
    GlobalService.setFields(storeNamespace, [], {
      //obj_type: 'dash_list_view',
      reset: 1,
      //cancelTokenId: setFieldsCancelTokenId,
    }).finally(async () => {
      // store.commit(storeNamespace + '/set_order', null)
      // store.commit(storeNamespace + '/set_reverse', null)
      // await fetchState()

      await changeStateParams({order: null, reverse: null})

      await nextTick()
      await hideLeftPanelLoader()
    })
  }

  return {
    selectedItemId,
    pageCurrent,
    fields: computed(() => store.getters[storeNamespace + '/fields']),
    visibleFields: computed(() => store.getters[storeNamespace + '/visibleFields']),
    order: computed(() => store.getters[storeNamespace + '/order']),
    reverse: computed(() => store.getters[storeNamespace + '/reverse']),
    pageLast: computed(() => store.getters[storeNamespace + '/pageLast']),
    itemsCount: computed(() => store.getters[storeNamespace + '/itemsCount']),
    scrollToSelected,
    changeSortByHandler,
    changeSortDescHandler,
    clickDatatableRowHandler,
    //changePageHandler,
    columnsChangeHandler,
    columnResizeHandler,
    resetView,
  }

}
